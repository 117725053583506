import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {Redirect} from "react-router";
import NavComponent from "../common/nav";
import HeaderComponent from "../common/header";
import FooterComponent from "../common/footer";
import CalendarPage from "../common/CalendarCore";
import {useTranslation} from "react-i18next";

const CalendarComponent = () => {

    const userReducer = useSelector(reducer => reducer.userReducer);

    if (!userReducer.loggedIn)
        return <Redirect to="/login/events"/>

    const {t, i18n} = useTranslation();

    return (
        <>
            <NavComponent/>
            <HeaderComponent section="Events"/>
            <section>
                <div className="mycontainer">
                    <h1 className="hero_text hero_text_align_center" style={{marginBottom: 50}}>
                        {t('views.events.title')}
                    </h1>
                    <CalendarPage/>
                </div>
            </section>
            <FooterComponent/>
        </>
    );
};

export default CalendarComponent;
