import React from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import LoginPage from './components/pages/login'
import ForgetRequestPage from './components/pages/forgetRequest'
import UpdatePasswordPage from './components/pages/updatePassword';
import ServicesPage from './components/pages/services';
import CircularsPage from './components/pages/circulars';
import PoliciesPage from './components/pages/policies';
import {autoLogin} from './redux/actions/userActions';
import ProfilePage from "./components/pages/profile";
import VideosPage from "./components/pages/videos";
import NominationPage from "./components/pages/nomination";
import "./App.css";
import ProtocolsPage from "./components/pages/protocols";
import GuidelinesPage from "./components/pages/guidelines";
import BookletsPage from "./components/pages/booklets";
import InformationPage from "./components/pages/information";
import ContactPage from "./components/pages/contact";
import {isLocalIT} from "./utils/utils";
import Calendar from "./components/pages/calendar";
import NotFoundPage from "./components/pages/not-found";
import HomePage from "./components/pages/home";
import HealthAreaPage from "./components/pages/healtharea";
import HealthCentersPage from "./components/pages/healthcenter";
import HealthCenterInformationPage from "./components/pages/healthcenterinformation";

class App extends React.Component {

    componentDidMount() {
        !isLocalIT() && this.props.autoLogin();
    }

    // !this.props.userReducer.loggedIn ? <h1>Sign Up or Login!</h1> : <h1>Welcome, {this.props.userReducer.user.name}</h1>
    render() {
        return (

            // ----- FOR TOMCAT -----
            <BrowserRouter basename={isLocalIT() ? 'portal' : ''}>
                <Switch>
                    <Route path="/login/:page">
                        <LoginPage/>
                    </Route>
                    <Route path="/login/">
                        <LoginPage/>
                    </Route>
                    <Route path="/forget">
                        <ForgetRequestPage/>
                    </Route>
                    <Route path="/updatePassword">
                        <UpdatePasswordPage/>
                    </Route>
                    <Route path="/resetpassword/:token">
                        <UpdatePasswordPage/>
                    </Route>
                    <Route path="/services">
                        <ServicesPage/>
                    </Route>
                    <Route path="/circulars">
                        <CircularsPage/>
                    </Route>
                    <Route path="/policies">
                        <PoliciesPage/>
                    </Route>
                    <Route path="/protocols">
                        <ProtocolsPage/>
                    </Route>
                    <Route path="/guidelines">
                        <GuidelinesPage/>
                    </Route>
                    <Route path="/information">
                        <InformationPage/>
                    </Route>
                    {
                        !isLocalIT() &&
                        <Route path="/Circulars">
                            <CircularsPage/>
                        </Route>
                    }
                    {
                        !isLocalIT() &&
                        <Route path="/events">
                            <Calendar/>
                        </Route>
                    }
                    {
                        !isLocalIT() &&
                        <Route path="/nomination/:id">
                            <NominationPage/>
                        </Route>
                    }
                    <Route path="/booklets">
                        <BookletsPage/>
                    </Route>
                    <Route path="/profile">
                        <ProfilePage/>
                    </Route>
                    <Route path="/videos">
                        <VideosPage/>
                    </Route>
                    <Route path="/contact">
                        <ContactPage/>
                    </Route>
                    <Route path="/not-found">
                        <NotFoundPage/>
                    </Route>
                    <Route path="/health-area/:healthArea">
                        <HealthAreaPage/>
                    </Route>
                    <Route path="/health-center/:healthCenter">
                        <HealthCentersPage/>
                    </Route>
                    <Route path="/hci">
                        <HealthCenterInformationPage/>
                    </Route>
                    <Route path="/">
                        <HomePage/>
                    </Route>
                </Switch>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userReducer: state.userReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        autoLogin: () => dispatch(autoLogin()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
