import React from "react";
import {Button, Card, Col, Popover, Row, Spin} from "antd";
import moment from "moment";
import {useState, useEffect} from "react";
import axios from "axios";
import {useQuery} from "react-query";
import {PushpinOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {I18nMonths} from "../../config/i18n/locales/months";
import {useDispatch} from "react-redux";
import {logoutUser} from "../../redux/actions/userActions";
import {Redirect} from "react-router";

const CalendarPage = () => {

    const [allowedRoles, SetAllowedRoles] = useState(["ROLE_HOA"]);
    const [showNomination, setShowNomination] = useState(false);
    const dispatch = useDispatch();

    const [dataSource, setDataSource] = useState([]);
    const [eventofmonthssarray, setEventofmonthssarray] = useState([]);
    const [eventofnextmonthssarray, setEventofnextmonthssarray] = useState([]);
    const [eventofnextnextmonthssarray, setEventofnextnextmonthssarray] = useState([]);

    const {t, i18n} = useTranslation();

    // const englishRegex = new RegExp("^[a-zA-Z]*$");
    const englishRegex = /^[A-Za-z0-9]*$/;

    const [employeeObj, setEmployeeObj] = useState(
        JSON.parse(localStorage.getItem("employee"))
    );

    const {data, isError, isLoading} = useQuery(
        ["events"],
        async () => {
            const res = await axios.get(
                "https://www.googleapis.com/calendar/v3/calendars/ads9ovestqt8ungnvfe19re29c@group.calendar.google.com/events?key=AIzaSyDfHtUfh1CijqmBm29wsFMBBP0oR9gFAdM"
            );
            const result = [];
            for (const i in res.data.items) {
                result.push(res.data.items[i]);
            }

            setDataSource(result);
            return res.data;
        },
        {refetchOnWindowFocus: false}
    );

    if (isError) {
        dispatch(logoutUser());
        return <Redirect to="/login"/>;
    }

    useEffect(() => {
        determineVisibility(allowedRoles, employeeObj, setShowNomination);
    });

    useEffect(() => {
        setEventofmonthssarray(
            dataSource
                .filter((val, key) => {
                    return val.start != null; // get only events with dates ()
                })
                .filter((val, key) => {
                    //filter only this month's events

                    // check if the value is a date or a datetime
                    if (val.start.dateTime) {
                        return (
                            moment().format("M") === moment(val.start.dateTime).format("M")
                        );
                    } else {
                        return (
                            moment().format("M") === moment(val.start.date).format("M")
                        );
                    }
                })
                .sort(sortEvents)
        );
        setEventofnextmonthssarray(
            dataSource
                .filter((val, key) => {
                    return val.start.date != null; // get only events with dates ()
                })
                .filter((val, key) => {
                    //filter only this month's events

                    // check if the value is a date or a datetime
                    if (val.start.dateTime) {
                        return (
                            moment().add(1, "months").format("M") ===
                            moment(val.start.dateTime).format("M")
                        );
                    } else {
                        return (
                            moment().add(1, "months").format("M") ===
                            moment(val.start.date).format("M")
                        );
                    }
                })
                .sort(sortEvents)
        );
        setEventofnextnextmonthssarray(
            dataSource
                .filter((val, key) => {
                    return val.start.date != null; // get only events with dates ()
                })
                .filter((val, key) => {
                    //filter only this month's events

                    // check if the value is a date or a datetime
                    if (val.start.dateTime) {
                        return (
                            moment().add(2, "months").format("M") ===
                            moment(val.start.dateTime).format("M")
                        );
                    } else {
                        return (
                            moment().add(2, "months").format("M") ===
                            moment(val.start.date).format("M")
                        );
                    }
                })
                .sort(sortEvents)
        );
    }, [dataSource]);

    const sortEvents = (a, b) => {
        if (a.start.dateTime && b.start.dateTime) {
            return new Date(a.start.dateTime) - new Date(b.start.dateTime);
        }

        if (a.start.date && b.start.date) {
            return new Date(a.start.date) - new Date(b.start.date);
        }

        if (a.start.dateTime && b.start.date) {
            return new Date(a.start.dateTime) - new Date(b.start.date);
        }

        if (a.start.date && b.start.dateTime) {
            return new Date(a.start.date) - new Date(b.start.dateTime);
        }
    }

    if (isLoading) {
        return (
            <Spin spinning={true} size="default">
                <div className="text-center">
                    <h6 style={{color: '#a6a6a6'}}>
                        {t('views.events.loading')}
                    </h6>
                </div>
            </Spin>
        );
    }

    const noEventsAvailable = () => {
        return (
            <div className="text-center">
                <h6 style={{color: '#a6a6a6'}}>
                    {t('views.events.noEventsAvailable')}
                </h6>
            </div>
        );
    }

    const eventItem = (val, key) => {

        let startDate;
        let endDate = '';
        let timeStart;
        let timeEnd;

        if (val.transparency) {
            startDate = moment(val.start.date).format("LL");
        } else {
            startDate = moment(val.start.dateTime).format("LL");
            endDate = moment(val.end.dateTime).format("LL");
            timeStart = moment(val.start.dateTime).format("h:mma");
            timeEnd = moment(val.end.dateTime).format("h:mma");
        }

        return (
            <Col gutter-row="true" key={key} xs={20} sm={16} md={12} lg={8} xl={8}
                 dir={englishRegex.test(val.summary) ? 'ltr' : 'rtl'}>
                <Card
                    id="CalendarCard"
                    title={val.location ? val.summary : null}
                    size="small"
                    key={key}
                    extra={location(val.summary, val.location, val.description)}
                >
                    {
                        !val.location &&
                        <h6 style={{textAlign: 'center'}}>{val.summary}</h6>
                    }
                    <p style={{textAlign: "center"}}>
                        {startDate} {endDate && endDate !== startDate && <span> - {endDate}</span>}
                    </p>
                    {
                        timeStart &&
                        <p style={{textAlign: "center"}}>
                            {timeStart} - {timeEnd && `${timeEnd}`}
                        </p>
                    }
                    {
                        !val.location &&
                        <p style={{textAlign: 'center'}}>{val.description}</p>
                    }
                </Card>
            </Col>
        );
    }

    const location = (title, location, description) => {

        if (location) {
            return (
                <Popover
                    placement="top"
                    title={t('views.events.information')}
                    content={() => {
                        return (
                            <div>
                                <h6 style={{textAlign: englishRegex.test(title) ? 'left' : 'right'}}>{title}</h6>
                                <p>Location: {location}</p>
                                {
                                    description &&
                                    <p style={{textAlign: englishRegex.test(description) ? 'left' : 'right'}}>{description}</p>
                                }
                            </div>
                        );
                    }}
                    trigger="click"
                >

                    <Button
                        type="primary"
                        shape="circle"
                        icon={<PushpinOutlined/>}
                    />
                </Popover>
            );
        }
    }

    return (
        <>
            <div
                className="site-card-wrapper"
            >
                <Row justify="space-around">
                    <Col span={20}>
                        <h1
                            style={{
                                display: 'flex',
                                justifyContent: i18n.language === 'en' ? 'flex-start' : 'flex-end'
                            }}
                        >
                            <span
                                style={{marginLeft: 5, marginRight: 5}}
                            > {I18nMonths(i18n.language, moment().format("MMMM").toLowerCase())} </span>
                            <span
                                style={{marginLeft: 5, marginRight: 5}}
                            > {t('views.events.monthEvent')} </span>
                        </h1>
                    </Col>
                </Row>
                <Row style={{paddingTop: 40, paddingBottom: 40}} justify="space-around" gutter={[7, 10]}>
                    {
                        eventofmonthssarray.length === 0 ?
                            noEventsAvailable()
                            : eventofmonthssarray.map((val, key) => {
                                return eventItem(val, key);
                            })
                    }
                </Row>
                <Row justify="space-around">
                    <Col span={20}>
                        <h1
                            style={{
                                display: 'flex',
                                justifyContent: i18n.language === 'en' ? 'flex-start' : 'flex-end'
                            }}
                        >
                            <span
                                style={{marginLeft: 5, marginRight: 5}}
                            > {I18nMonths(i18n.language, moment().add(1, "months").format("MMMM").toLowerCase())} </span>
                            <span
                                style={{marginLeft: 5, marginRight: 5}}
                            > {t('views.events.monthEvent')} </span>
                        </h1>
                    </Col>
                </Row>
                <Row style={{paddingTop: 40, paddingBottom: 40}} justify="space-around" gutter={[7, 10]}>
                    {
                        eventofnextmonthssarray.length === 0 ?
                            noEventsAvailable()
                            : eventofnextmonthssarray.map((val, key) => {
                                return eventItem(val, key);
                            })
                    }
                </Row>
                <Row justify="space-around">
                    <Col span={20}>
                        <h1
                            style={{
                                display: 'flex',
                                justifyContent: i18n.language === 'en' ? 'flex-start' : 'flex-end'
                            }}
                        >
                            <span
                                style={{marginLeft: 5, marginRight: 5}}
                            > {I18nMonths(i18n.language, moment().add(2, "months").format("MMMM").toLowerCase())} </span>
                            <span
                                style={{marginLeft: 5, marginRight: 5}}
                            > {t('views.events.monthEvent')} </span>
                        </h1></Col>
                </Row>
                <Row style={{paddingTop: 40, paddingBottom: 40}} justify="space-around" gutter={[7, 10]}>
                    {
                        eventofnextnextmonthssarray.length === 0 ?
                            noEventsAvailable()
                            : eventofnextnextmonthssarray.map((val, key) => {
                                return eventItem(val, key);
                            })
                    }
                </Row>
            </div>
        </>
    );
};

export default CalendarPage;

const determineVisibility = (roles, employee, Nomination) => {
    if (roles.indexOf(employee?.user.role) > -1) {
        Nomination(true);
    }
};
