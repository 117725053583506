// months in arabic and english
export const I18nMonths = (lang, valueOf) => {
    if (lang === 'ar') {
        switch (valueOf) {
            case "january":
                return 'يناير';
            case "february":
                return 'فبراير';
            case "march":
                return 'مارس';
            case "april":
                return 'أبريل';
            case "may":
                return 'مايو';
            case "june":
                return 'يونيو';
            case "july":
                return 'يوليو';
            case "august":
                return 'أغسطس';
            case "september":
                return 'سبتمبر';
            case "october":
                return 'أكتوبر';
            case "november":
                return 'نوفمبر';
            case "december":
                return 'ديسمبر';
            default:
                return 'الشهر غير معروف';
        }
    } else {
        switch (valueOf) {
            case "january":
                return 'January';
            case "february":
                return 'February';
            case "march":
                return 'March';
            case "april":
                return 'April';
            case "may":
                return 'May';
            case "june":
                return 'June';
            case "july":
                return 'July';
            case "august":
                return 'August';
            case "september":
                return 'September';
            case "october":
                return 'October';
            case "november":
                return 'November';
            case "december":
                return 'December';
            default:
                return 'Month is unknown';
        }
    }
}
