import React from 'react'
import * as url from "url";
import * as crypto from "crypto";

const LocationPin = ({text}) => (
    <div className="pin">
        <p className="pin-text">{text}</p>
    </div>
)

export default ({location}) => {

    const center = (location) => {
        return {
            lat: Number(location.lat),
            lng: Number(location.lng)
        }
    }

    /**
     * Convert from 'web safe' base64 to true base64.
     *
     * @param  {string} safeEncodedString The code you want to translate
     *                                    from a web safe form.
     * @return {string}
     */
    function removeWebSafe(safeEncodedString) {
        return safeEncodedString.replace(/-/g, '+').replace(/_/g, '/');
    }

    /**
     * Convert from true base64 to 'web safe' base64
     *
     * @param  {string} encodedString The code you want to translate to a
     *                                web safe form.
     * @return {string}
     */
    function makeWebSafe(encodedString) {
        return encodedString.replace(/\+/g, '-').replace(/\//g, '_');
    }

    /**
     * Takes a base64 code and decodes it.
     *
     * @param  {string} code The encoded data.
     * @return {string}
     */
    function decodeBase64Hash(code) {
        // "new Buffer(...)" is deprecated. Use Buffer.from if it exists.
        return Buffer.from ? Buffer.from(code, 'base64') : new Buffer(code, 'base64');
    }

    /**
     * Takes a key and signs the data with it.
     *
     * @param  {string} key  Your unique secret key.
     * @param  {string} data The url to sign.
     * @return {string}
     */
    function encodeBase64Hash(key, data) {
        return crypto.createHmac('sha1', key).update(data).digest('base64');
    }

    /**
     * Sign a URL using a secret key.
     *
     * @param  {string} path   The url you want to sign.
     * @param  {string} secret Your unique secret key.
     * @return {string}
     */
    function sign(path, secret) {
        const uri = url.parse(path);
        const safeSecret = decodeBase64Hash(removeWebSafe(secret));
        const hashedSignature = makeWebSafe(encodeBase64Hash(safeSecret, uri.path));
        return url.format(uri) + '&signature=' + hashedSignature;
    }


    const generateGoogleMapStaticUrl = (location) => {

        return sign(`https://maps.googleapis.com/maps/api/staticmap?center=${location.lat},${location.lng}&zoom=15&size=400x400&markers=color:red%7Clabel:Yousef%7C${location.lat},${location.lng}&key=AIzaSyDtrDJwZ5chGt8VlhukC1Z7WWI3b7KuTlo`, 'RU7I2vwihw9LGdpt5ZWVRpWbwd4=')
    }

    return (

        <div style={{height: '400px', width: '400px'}}>
            <a href={`https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lng}`} target="_blank">
                <img src={generateGoogleMapStaticUrl(location)} alt="map" style={{width: '100%', height: '100%'}}/>
            </a>
        </div>

        // <GoogleMapReact
        //     bootstrapURLKeys={{key: 'AIzaSyDtrDJwZ5chGt8VlhukC1Z7WWI3b7KuTlo'}}
        //     defaultCenter={center(location)}
        //     defaultZoom={17}
        // >
        //     <LocationPin
        //         lat={location.lat}
        //         lng={location.lng}
        //         text={location.address}
        //     />
        // </GoogleMapReact>
    )
}
