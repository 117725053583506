import React, {useEffect, useState} from "react";
import NavComponent from "../common/nav";
import HeaderComponent from "../common/header";
import FooterComponent from "../common/footer";
import {Redirect, useLocation, useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import service from "../../config/service";
import Healthcenters from "../common/healthcenter";
import {Button} from "antd";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import Nav from "../common/healthCenterInfromation/nav";

export const HealthCentersPage = () => {

    const {t} = useTranslation();
    const [mainData, setMainData] = useState([]);
    const [selectedHealthCenter, setSelectedHealthCenter] = useState(null);

    const {healthCenter} = useParams();
    const {i18n} = useTranslation();
    const navigation = useHistory();
    const location = useLocation();

    const {data, isError, isLoading} = useQuery('healthCenterService', async () => {
        const res = await service.get('/healthCenterService');
        return res.data;
    }, {refetchOnWindowFocus: false});

    const healthAreaLocalization = [{
        key: 'Ahmadi Health Area', en: "Ahmadi Health Area", ar: "منطقة الأحمدي الصحية"
    }, {
        key: 'Capital Health Area', en: "Capital Health Area", ar: "منطقة العاصمة الصحية"
    }, {
        key: 'Farwaniya Health Area', en: "Farwaniya Health Area", ar: "منطقة الفروانية الصحية"
    }, {
        key: 'Hawalli Health Area', en: "Hawalli Health Area", ar: "منطقة حولي الصحية"
    }, {
        key: 'Jahra Health Area', en: "Jahra Health Area", ar: "منطقة الجهراء الصحية"
    }, {
        key: 'Mubarak AlKabeer Health Area', en: "Mubarak Al Kabeer Health Area", ar: "منطقة مبارك الكبير الصحية"
    }]

    useEffect(() => {

        if (data) {
            setMainData(data);
        }

        const getData = async () => {
            new Promise((resolve, reject) => {
                try {
                    const d = [];
                    const dha = [];
                    const dhc = [];

                    // for each key obj ket in data convert to array and add to healthCenterData
                    if (data) {
                        const keys = Object.keys(data);
                        for (let index = 0; index < keys.length; index++) {
                            const key = keys[index];
                            d.push({
                                key: index,
                                title: healthAreaLocalization.find((h) => h.key === key),
                                label: healthAreaLocalization.find((h) => h.key === key),
                                items: data[key]
                            });
                            dha.push({
                                key: String(index), label: healthAreaLocalization.find((h) => h.key === key),
                            });
                            dhc[index] = '-1';
                        }
                    }
                    resolve({d, dha, dhc});
                } catch (e) {
                    reject(e);
                }
            }).then(({d, dha, dhc}) => {
                if (healthCenter) {
                    let key = -1;
                    let healthAreaIndex = -1;

                    for (let i = 0; i < d.length; i++) {
                        const h = d[i];
                        for (let j = 0; j < h.items.length; j++) {
                            if (h.items[j].centerName.nameEng.toLowerCase() === healthCenter.toLowerCase() ||
                                h.items[j].centerName.nameArb === healthCenter) {
                                key = h.key;
                                healthAreaIndex = j;
                                break;
                            }
                        }
                    }

                    // if healthArea is not in the list redirect to 404
                    if (healthAreaIndex === -1) {
                        return <Redirect to="/not-found"/>
                    }

                    if (d[key].items[healthAreaIndex]) {
                        setSelectedHealthCenter(d[key].items[healthAreaIndex]);
                    }
                } else {
                    // if healthArea is not in the list redirect to 404
                    return <Redirect to="/not-found"/>
                }
            }).catch((e) => {
                console.error(e);
            });
        }

        getData();

        // cancel subscription to useEffect
        return () => {
            getData();
        }
    }, [healthCenter, data]);

    const capitalizeTitle = (title) => {
        // capitalize first letter of each word
        return title.split(' ').map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }).join('\n');
    }

    return (
        <>
            {location.state && location.state.f === 'hci' ? <Nav/> : <NavComponent/>}
            <HeaderComponent section="Guidelines"/>
            <section>
                <div className="mycontainer">
                    <div
                        style={{
                            display: 'flex',
                            padding: '16px 8px',
                            flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                        }}
                    >
                        <Button
                            icon={i18n.dir() === 'rtl' ? <RightOutlined/> : <LeftOutlined/>}
                            size='middle'
                            onClick={() => navigation.goBack()}
                        />
                        <h1
                            id={"health-area-title"}
                            className="hero_text hero_text_align_center"
                            style={{
                                marginTop: "60px",
                                marginBottom: "80px",
                                textAlign: i18n.dir() === 'rtl' ? 'right' : 'left',
                                fontFamily: "Montserrat, sans-serif",
                                fontWeight: 800,
                                fontOpticalSizing: "auto",
                                fontStyle: "normal",
                                textShadow: "0 0 5px #1c3564",
                            }}
                        >
                            {
                                selectedHealthCenter ?
                                i18n.language === "ar"
                                    ? capitalizeTitle(selectedHealthCenter.centerName.nameArb)
                                    : capitalizeTitle(selectedHealthCenter.centerName.nameEng)
                                    : t('healthCenters')
                            }
                        </h1>
                    </div>
                    <Healthcenters healthCenter={selectedHealthCenter} isLoading={isLoading}/>
                </div>
            </section>
            <FooterComponent/>
        </>
    )
}

export default HealthCentersPage;
